import React, { Fragment, useState, useEffect } from 'react';
import './Cargos.css';
import NavBarAdmin from '../../reutilizables/navBar/NavBarAdmin';
import Asidebar from '../../reutilizables/asidebar/Asidebar';
import ModalAltaCargos from '../../formularios/modalCargos/ModalAltaCargos';
import ModalEditarCargos from '../../formularios/modalCargos/ModalEditarCargos';
import { Redirect } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const Cargos = () => {

    const baseUrl = window.$baseUrl;

    const [data, setData] = useState([]);
    // const [cargos, setCargos] = useState([]);
    const [modalInsertar, setModalInsertar] = useState(false);
    const [modalEditar, setModalEditar] = useState(false);
    const [modalEliminar, setModalEliminar] = useState(false);
    const [cargoSeleccionado, setCargoSeleccionado] = useState({
        id_cargo: "",
        nombre_cargo: ""
    });

    const handleChange = e => {
        const { name, value } = e.target;
        setCargoSeleccionado((prevState) => ({
            ...prevState,
            [name]: value
        }))

    }

    const abrirCerrarModalInsertar = () => {
        setModalInsertar(!modalInsertar);
        document.getElementById("Form-insertarC").reset();
    }

    const abrirCerrarModalEditar = () => {
        setModalEditar(!modalEditar);
    }

    const abrirCerrarModalEliminar = () => {
        setModalEliminar(!modalEliminar);
    }


    const seleccionarCargo = (cargo, caso) => {
        setCargoSeleccionado(cargo);

        (caso === "Editar") ?
            abrirCerrarModalEditar() :
            abrirCerrarModalEliminar()
    }



    const peticionGet = async () => {
        await axios.get(baseUrl + "/api/todos/cargos")
            .then(response => {
                setData(response.data);
            }).catch(error => {
                console.log(error);
            })
    }


    const peticionPost = async (e) => {

        e.preventDefault();


        await axios.post(baseUrl + "/api/cargo", {
            nombre_cargo: cargoSeleccionado.nombre_cargo,

        }).then(response => {
            console.log(response);
            Swal.fire({
                icon: 'success',
                title: '¡Cargo agregado!',
                confirmButtonText: 'Aceptar',
            });

        }).catch(error => {
            console.log(error);
        })

        peticionGet();
        abrirCerrarModalInsertar();
    }



    const peticionPut = async (e) => {
        e.preventDefault();

        await axios.put(baseUrl + "/api/cargo/" + cargoSeleccionado.id_cargo, {

            id_cargo: cargoSeleccionado.id_cargo,
            nombre_cargo: cargoSeleccionado.nombre_cargo,
        
        })
            .then(response => {
                console.log(response);
                peticionGet();
                Swal.fire({
                    icon: 'success',
                    title: '¡Usuario actualizado!',
                    confirmButtonText: 'Aceptar',
                });
               
            })
            
            .catch(error => {
                console.log(error);
            });



        peticionGet();

        abrirCerrarModalEditar();
    }


    const peticionDelete = (id) => {

        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡Una vez eliminado no podrás recuperar la información!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(baseUrl + "/api/cargo/" + id, {

                }).then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: '¡Cargo eliminado!',
                        confirmButtonText: 'Aceptar',
                    });

                    peticionGet();
                }).catch(e => {
                    console.log(e);
                });


            }
        })

    }

    const [abrirMenu, setAbrirMenu] = useState(false);

    const abrirmenu = () => {
        setAbrirMenu(!abrirMenu);
    }

    const agrandarDashboard = {
       
    }

    const encogerDashboard = {
        display:'flex'
    }



    useEffect(() => {
        peticionGet();
    }, [])



    return (
        <Fragment>
            {!cookies.get('correo') && <Redirect to="./" />}

            {modalEditar ?
                <ModalEditarCargos cargoSeleccionado={cargoSeleccionado} seleccionarCargo={seleccionarCargo} peticionPut={peticionPut} modalInsertar={modalInsertar} peticionPost={peticionPost} modalEditar={modalEditar} abrirCerrarModalEditar={abrirCerrarModalEditar} abrirCerrarModalInsertar={abrirCerrarModalInsertar} handleChange={handleChange} />
                :
                <ModalAltaCargos cargoSeleccionado={cargoSeleccionado} seleccionarCargo={seleccionarCargo} peticionPut={peticionPut} modalInsertar={modalInsertar} peticionPost={peticionPost} modalEditar={modalEditar} abrirCerrarModalEditar={abrirCerrarModalEditar} abrirCerrarModalInsertar={abrirCerrarModalInsertar} handleChange={handleChange} />
            }

            <div className="contenedor-principal-dashboard">

                <div className="contenido-dashboard-asidebar">

                    <div style={abrirMenu ? encogerDashboard : agrandarDashboard} className="contenedor-dashboard-asidebar" >
                        <Asidebar />
                    </div>

                    <div className="contenedor-dashboard">
                        <div className="contenedor-dashboard-navbar">
                            <NavBarAdmin abrirMenu={abrirMenu} setAbrirMenu={setAbrirMenu} abrirmenu={abrirmenu} />
                        </div>

                        <div className="contenedor-principal-cargos">
                            <div className="contenedor-cargos-agregar">
                                <button onClick={() => abrirCerrarModalInsertar()}> <i className="fas fa-plus"> </i>  Agregar Cargo</button>
                            </div>

                            <div className="contenedor-tabla-cargos">
                                <table className="table">
                                    <thead className="thead">
                                        <tr>
                                            <th>#</th>
                                            <th>Nombre Cargo</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody className="tbody">


                                    {data.map(items => 
                                        <tr key={items.id_cargo}>
                                            <td id="id_usuario" >{items.id_cargo}</td>
                                            <td id="nom_usuario" >{items.nombre_cargo}</td>
                                            <td id="botones-tabla">
                                                <button onClick={() => seleccionarCargo(items, "Editar")} id="editar" className="btn_editar" title="Editar"><i className="fas fa-pen"></i></button>

                                                <button onClick={e => peticionDelete(items.id_cargo)} id="remover" className="btn_remover" title="Eliminar"><i className="fas fa-trash"></i></button>
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>


                </div>

            </div>



        </Fragment>
    )
}

export default Cargos;