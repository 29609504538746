import React, { Fragment, useState, useEffect } from 'react';
import './Construcciones.css';
import NavBar from '../navbar/NavBar';
import Footer from '../footer/Footer';
import fondo_construcciones from '../../imagenes/fondo_construcciones.jpg';
import energia from '../../imagenes/proyectos/energia.JPG';
import carretera2 from '../../imagenes/proyectos/carretera2.jpeg';
import agua_potable from '../../imagenes/proyectos/agua_potable.jpg';

import { Helmet } from "react-helmet";

const Construcciones = () => {


    return (

        <Fragment>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Construcciónes</title>
                <link rel="canonical" href="https://www.grupocitra.com.mx/construcciones" />
                <meta name="description" content="Construimos obra pública y privada de acuerdo a sus proyectos." />
                <meta name="robots" content="noindex" />
            </Helmet>


            <NavBar />
            <div data-aos="flip-up" className="contenedor-proyectos-titulo">
                <img src={fondo_construcciones} alt="fondo proyecto" title="fondo proyecto" />
                <div className="proyectos-titulo">
                    <h1>Construcción</h1>
                    <h3>"Con la firme visión de construir lo mejor para tí"</h3>
                </div>
            </div>

            <div data-aos="slide-up" className="contenedor-proyectos-info">
                <p>Construimos obra pública y privada de acuerdo a sus proyectos.</p>
            </div>

            <div data-aos="zoom-out-up" className="contenedor-proyectos-items">
                <p>Servicios que ofrecemos en el área de construcción:</p>
                <li>Pavimentación (Accesos, estacionamientos, calles, etcétera).</li>
                <li>Suministro e instalación de señalamiento vial (vertical y horizontal).</li>
                <li>Red eléctrica de alta y media tensión.</li>
                <li>Alumbrado público y privado.</li>
                <li>Red de agua potable.</li>
                <li>Obra civil (Edificios, bodegas, casa-habitación, etcétera).</li>
            </div>

            <div data-aos="zoom-out-up" className="contenedor-proyectos-imagenes">

                <img src={agua_potable} alt="agua potable" title="agua potable" />
                <img src={energia} alt="energia" title="energia" />
                <img src={carretera2} alt="carretera" title="carretera" />


            </div>




            <Footer />
        </Fragment>



    )
}

export default Construcciones;