import React, { Fragment, useState, useEffect } from 'react';
import './Supervisiones.css';
import NavBar from '../navbar/NavBar';
import Footer from '../footer/Footer';
import fondo_supervisiones from '../../imagenes/fondo_supervisiones.jpg';
import supervision_2 from '../../imagenes/proyectos/supervision_2.JPG';
import supervision_3 from '../../imagenes/proyectos/supervision_3.JPG';
import supervision_4 from '../../imagenes/proyectos/supervision_4.JPG';

import { Helmet } from "react-helmet";

const Supervisiones = () => {


    return (

        <Fragment>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Supervisión</title>
                <link rel="canonical" href="https://www.grupocitra.com.mx/supervisiones" />
                <meta name="description" content="Realizamos un completo seguimiento a las obras en proceso de construcción dando recomendaciones para el correcto cumplimiento de los estándares nacionales e internacionales." />
                <meta name="robots" content="noindex" />
            </Helmet>

            <NavBar />
            <div data-aos="flip-up" className="contenedor-proyectos-titulo">
                <img src={fondo_supervisiones} alt="fondo proyecto" title="fondo proyecto" />
                <div className="proyectos-titulo">
                    <h1>Supervisión</h1>
                    <h3>"Aseguramos entregarte un trabajo de calidad"</h3>
                </div>
            </div>

            <div data-aos="slide-up" className="contenedor-proyectos-info">
                <p>Realizamos un completo seguimiento a las obras en proceso de construcción dando recomendaciones para el correcto cumplimiento de los estándares nacionales e internacionales.</p>
            </div>

            <div data-aos="zoom-out-up" className="contenedor-proyectos-items">
                <p>Servicios que ofrecemos en el área de supervisión:</p>
                <li>Supervisión de avances de obra.</li>
                <li>Supervisión estructural.</li>
                <li>Supervisión por estación total.</li>
                <li>Supervisión por dron.</li>
                <li>Avances de estimaciones.</li>
            </div>

            <div data-aos="zoom-out-up" className="contenedor-proyectos-imagenes">
                <img src={supervision_3} alt="supervision" title="supervision" />
                <img src={supervision_2} alt="supervision" title="supervision" />
                <img src={supervision_4} alt="supervision" title="supervision" />
            </div>




            <Footer />
        </Fragment>



    )
}

export default Supervisiones;