import React, { Fragment } from 'react';
import './Slider.css';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import img1 from '../../imagenes/img1.png';
import img2 from '../../imagenes/img2.png';
import img4 from '../../imagenes/img4.png';
import img6 from '../../imagenes/img6.png';
import img5 from '../../imagenes/img5.png';
import img7 from '../../imagenes/img7.png';
import img8 from '../../imagenes/img8.png';
import img9 from '../../imagenes/img9.png';

const Slider = () => {
    return (
        <Fragment>
            <div className="contenedor-slider">
                <div data-aos="fade-up" className="contenedor-slider-imagenes">
                    <Splide options={{
                        type: 'loop',
                        perPage: 1,
                        autoplay: true,
                        pauseOnHover: false,

                    }}
                    >
                        <SplideSlide>
                            <img src={img1} alt="carretera" />
                        </SplideSlide>
                        <SplideSlide>
                            <img src={img2} alt="paso peatonal" />
                        </SplideSlide>
                        <SplideSlide>
                            <img src={img4} alt="paso peatonal" />
                        </SplideSlide>
                        <SplideSlide>
                            <img src={img7} alt="paso peatonal" />
                        </SplideSlide>
                        <SplideSlide>
                            <img src={img6} alt="paso peatonal" />
                        </SplideSlide>
                        <SplideSlide>
                            <img src={img5} alt="paso peatonal" />
                        </SplideSlide>
                        <SplideSlide>
                            <img src={img9} alt="paso peatonal" />
                        </SplideSlide>
                        <SplideSlide>
                            <img src={img8} alt="paso peatonal" />
                        </SplideSlide>
                    </Splide>
                </div>
            </div>
        </Fragment>
    )
}

export default Slider;