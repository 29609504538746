import React, { Fragment, useState, useEffect } from 'react';
import './AgregarClientes.css';
import NavBarAdmin from '../../reutilizables/navBar/NavBarAdmin';
import Asidebar from '../../reutilizables/asidebar/Asidebar';
import ModalAltaClientes from '../../formularios/modalClientes/ModalAltaClientes';
import ModalEditarClientes from '../../formularios/modalClientes/ModalEditarClientes';
import { Redirect } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const AgregarClientes = () => {

    const baseUrl = window.$baseUrl;

    const [data, setData] = useState([]);
    const [modalInsertar, setModalInsertar] = useState(false);
    const [modalEditar, setModalEditar] = useState(false);
    const [modalEliminar, setModalEliminar] = useState(false);
    const [proyectoSeleccionado, setProyectoSeleccionado] = useState({
        id_cliente: ""
    });

    
    const [file, setFile] = useState(null);

    const handleChange = e => {
        const { name, value } = e.target;
        setProyectoSeleccionado((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleChanges = async (e) => {
        const file = e.target.files[0];
      const base64 = await convertBase64(file); 
      setFile(base64);
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () =>{
                resolve(fileReader.result);

            
        };

        fileReader.onerror = (error) => {
            reject(error);
        }
       })
    }



    

    const abrirCerrarModalInsertar = () => {
        setModalInsertar(!modalInsertar);
        document.getElementById("Form-insertarC").reset();
    }

    const abrirCerrarModalEditar = () => {
        setModalEditar(!modalEditar);
    }

    const abrirCerrarModalEliminar = () => {
        setModalEliminar(!modalEliminar);
    }


    const seleccionarProyecto = (cargo, caso) => {
        setProyectoSeleccionado(cargo);
        setFile(cargo.logo_empresa);

        (caso === "Editar") ?
            abrirCerrarModalEditar() :
            abrirCerrarModalEliminar()
    }



    const peticionGet = async () => {
        await axios.get(baseUrl + "/api/todos/clientes")
            .then(response => {
                setData(response.data);
            }).catch(error => {
                console.log(error);
            })
    }


    const peticionPost = async (e) => {

        e.preventDefault();


        await axios.post(baseUrl + "/api/cliente", {

            logo_empresa: file
        }).then(response => {
            peticionGet();
            
            console.log(response);
            Swal.fire({
                icon: 'success',
                title: '¡Cliente agregado!',
                confirmButtonText: 'Aceptar',
            });

            peticionGet();
            peticionGet();
            peticionGet();

        }).catch(error => {
            console.log(error);
        })

        peticionGet();
        abrirCerrarModalInsertar();
    }



    const peticionPut = async (e) => {
        e.preventDefault();

        await axios.put(baseUrl + "/api/cliente/" + proyectoSeleccionado.id_cliente, {

            id_cliente: proyectoSeleccionado.id_cliente,
            logo_empresa: file

        })
            .then(response => {
                peticionGet();
                console.log(response);
                peticionGet();
                Swal.fire({
                    icon: 'success',
                    title: '¡Cliente actualizado!',
                    confirmButtonText: 'Aceptar',
                });

                peticionGet();
                peticionGet();
                peticionGet();

            })

            .catch(error => {
                console.log(error);
            });



        peticionGet();

        abrirCerrarModalEditar();
    }

   
    const peticionDelete = (id) => {

        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡Una vez eliminado no podrás recuperar la información!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(baseUrl + "/api/cliente/" + id, {

                }).then(response => {
                    peticionGet();
                    Swal.fire({
                        icon: 'success',
                        title: '¡Cliente eliminado!',
                        confirmButtonText: 'Aceptar',
                    });

                    peticionGet();
                    peticionGet();
                    peticionGet();

                }).catch(e => {
                    console.log(e);
                });


            }
            peticionGet();
        })
        

    }

    const [abrirMenu, setAbrirMenu] = useState(false);

    const abrirmenu = () => {
        setAbrirMenu(!abrirMenu);
    }

    const agrandarDashboard = {
       
    }

    const encogerDashboard = {
        display:'flex'
    }



    useEffect(() => {
        peticionGet();
    }, [])



    return (
        <Fragment>
            {!cookies.get('correo') && <Redirect to="./" />}

            {modalEditar ?
                <ModalEditarClientes proyectoSeleccionado={proyectoSeleccionado} file={file} seleccionarProyecto={seleccionarProyecto} peticionPut={peticionPut} modalInsertar={modalInsertar} peticionPost={peticionPost} modalEditar={modalEditar} abrirCerrarModalEditar={abrirCerrarModalEditar} abrirCerrarModalInsertar={abrirCerrarModalInsertar} handleChange={handleChange} handleChanges={handleChanges} />
                :
                <ModalAltaClientes proyectoSeleccionado={proyectoSeleccionado} file={file} seleccionarProyecto={seleccionarProyecto} peticionPut={peticionPut} modalInsertar={modalInsertar} peticionPost={peticionPost} modalEditar={modalEditar} abrirCerrarModalEditar={abrirCerrarModalEditar} abrirCerrarModalInsertar={abrirCerrarModalInsertar} handleChange={handleChange} handleChanges={handleChanges} />
            }

            <div className="contenedor-principal-dashboard">

                <div className="contenido-dashboard-asidebar">

                    <div style={abrirMenu ? encogerDashboard : agrandarDashboard} className="contenedor-dashboard-asidebar" >
                        <Asidebar />
                    </div>

                    <div className="contenedor-dashboard">
                        <div className="contenedor-dashboard-navbar">
                            <NavBarAdmin abrirMenu={abrirMenu} setAbrirMenu={setAbrirMenu} abrirmenu={abrirmenu} />
                        </div>

                        <div className="contenedor-principal-cargos">
                            <div className="contenedor-cargos-agregar">
                                <button onClick={() => abrirCerrarModalInsertar()}> <i className="fas fa-plus"> </i>  Agregar Cliente</button>
                            </div>

                            <div className="contenedor-tabla-cargos">
                                <table className="table">
                                    <thead className="thead">
                                        <tr>
                                            <th>#</th>
                                            <th>Logo Empresa</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody className="tbody">


                                        {data.map(items =>
                                            <tr key={items.id_cliente}>
                                                <td id="id_usuario">{items.id_cliente}</td>
                                                <td><img width="200px" src={items.logo_empresa} alt="imagen"/></td>
                                                
                                                <td id="botones-tabla">
                                                    <button onClick={() => seleccionarProyecto(items, "Editar")} id="editar" className="btn_editar" title="Editar"><i className="fas fa-pen"></i></button>

                                                    <button onClick={e => peticionDelete(items.id_cliente)} id="remover" className="btn_remover" title="Eliminar"><i className="fas fa-trash"></i></button>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>


                </div>

            </div>



        </Fragment>
    )
}

export default AgregarClientes;