import React, { Fragment } from "react";
import './ModalCargos.css';



const ModalEditarCargos = ({ modalEditar, modalInsertar, peticionPut, peticionPost, seleccionarCargo, cargoSeleccionado, abrirCerrarModalEditar, abrirCerrarModalInsertar, handleChange }) => {


  return (
    <Fragment>
      <div className={modalEditar ? "Modal-desactive" : "Modal-active"}>
        <div className="contend-modalC">
          <div className="ModalHeaderC">
            <h2>Editar Cargo</h2>
            <i className="fas fa-times" onClick={() => abrirCerrarModalEditar()} id="icon-close"></i>
          </div>
          <div className="ModalBodyC">
            <div className="FormGroupC">
              <form autocomplete="off" onSubmit={peticionPut} id="miFormC">

                <label htmlFor="nombre_cargo">Nombre cargo</label>
                <input onChange={handleChange} name="nombre_cargo" value={cargoSeleccionado && cargoSeleccionado.nombre_cargo} required type="text" />

                <div className="ModalFooter">
                  <input id="yes" type="submit" value="Guardar Cambios" />
                  <button onClick={() => abrirCerrarModalEditar()} id="cancel">Cancelar</button>
                </div>
              </form>




            </div>
          </div>
        </div>
      </div>

    </Fragment>
  );
}

export default ModalEditarCargos;
