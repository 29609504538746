//Importación de recursos
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';



//Importación de vistas
import App from './App';
import Contacto from './componentes/contacto/Contacto';
import Clientes from './componentes/clientes/Clientes';
import Proyectos from './componentes/proyectos/Proyectos';
import Login from './componentes/login/Login';
import ForgotPassword from './componentes/forgotPassword/ForgotPassword';
import Dashboard from './componentes/dashboard/Dashboard';
import Profile from './componentes/profile/Profile';  
import Cargos from './componentes/cargos/Cargos';
import Usuarios from './componentes/usuarios/Usuarios';
import AgregarProyectos from './componentes/agregarProyectos/AgregarProyectos';
import AgregarDiferenciador from './componentes/agregarDiferenciador/AgregarDiferenciador';
import AgregarClientes from './componentes/agregarClientes/AgregarClientes';
import Construcciones from './componentes/construcciones/Construcciones';
import Supervisiones from './componentes/supervisiones/Supervisiones';


//conexion 

// window.$baseUrl = "http://localhost:30010";

window.$baseUrl = "https://api-grupocitra.herokuapp.com";

const Index = () => {
  
  return (
    <Router>
      <Route exact path="/" component={App} />
      <Route path="/inicio" component={App} />
      <Route path="/contacto" component={Contacto} />
      <Route path="/clientes" component={Clientes} />
      <Route path="/proyectos" component={Proyectos} />
      <Route path="/construcciones" component={Construcciones} /> 
      <Route path="/supervisiones" component={Supervisiones} /> 
      <Route path="/login" component={Login} />
      <Route path="/forgotpassword" component={ForgotPassword} />
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/profile" component={Profile} /> 
      <Route path="/cargos" component={Cargos} /> 
      <Route path="/usuarios" component={Usuarios} /> 
      <Route path="/agregarProyectos" component={AgregarProyectos} /> 
      <Route path="/agregarDiferenciador" component={AgregarDiferenciador} /> 
      <Route path="/agregarClientes" component={AgregarClientes} /> 

    </Router>
  )
}

ReactDOM.render(<Index />, document.getElementById('root'));
