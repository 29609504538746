import React, { Fragment, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import "./Login.css";
import cargando from "../../imagenes/cargando.gif";
import logo from "../../imagenes/logoCitra.png";
import Swal from 'sweetalert2';
import axios from 'axios';
import Cookies from 'universal-cookie';



const Login = () => {

  const baseUrl = window.$baseUrl;

  const cookies = new Cookies();


  const [credenciales, setCredenciales] = useState({
    email: "",
    password: "",
  });



  const handleInputChange = (event) => {

    setCredenciales({
      ...credenciales,
      [event.target.name]: event.target.value,
    });
  };

  const [enviado, setEnviado] = useState(true);

  const iniciarSesion = async (e) => {

    e.preventDefault();

    if (credenciales.email.length <= 0) {
      Swal.fire({
        title: "¡Campo vacio!",
        text: "Ingresa tu correo electrónico",
        icon: "warning",
        confirmButtonText: "Aceptar",
        timer: "2000"

      });
    } else if (credenciales.password.length <= 0) {
      Swal.fire({
        title: "¡Campo vacio!",
        text: "Ingresa tu contraseña",
        icon: "warning",
        confirmButtonText: "Aceptar",
        timer: "2000"
      });
      setEnviado(true);
    } else {
      setEnviado(false);
      await axios.get(baseUrl + "/api/usuarios/" + credenciales.email + "/" + credenciales.password)
        .then(response => {

          const savedata = response.data;

          const datauser = (Object.keys(response.data).length);


          if (datauser > 0) {
            cookies.set('id_usuario', savedata.id_usuario, { path: "/" });
            cookies.set('nombre_completo_usuario', savedata.nombre_completo_usuario, { path: "/" });
            cookies.set('correo', savedata.correo, { path: "/" });
            cookies.set('nombre_cargo', savedata.nombre_cargo, { path: "/" });

            window.location.href = "./login";

          } else {

            Swal.fire({
              title: "¡Aviso!",
              text: "El usuario o la contraseña no son correctos",
              icon: "warning",
              confirmButtonText: "Aceptar",
              timer: "4000"
            });
            setEnviado(true);
          }

        })

        .catch(error => {
          console.log(error);
        })

    }

  }


  return (
    <Fragment>

      {cookies.get('correo') && <Redirect to="./dashboard" />}
      <div className="fondo-login">
        <div className="content-login">
          <img src={logo} alt="Logo Grupo Citra" />
          <h1>Iniciar Sesión</h1>
          <form onSubmit={iniciarSesion}>
            <label id="label">Correo</label>
            <input
              id="email"
              name="email"
              type="email"
              placeholder="Correo electrónico"
              onChange={handleInputChange}


            ></input>
            <label id="label">Contraseña</label>
            <input
              id="password"
              name="password"
              type="password"
              placeholder="Contraseña"
              onChange={handleInputChange}
            ></input>
            {

              enviado ?
                <input

                  id="send"
                  type="submit"
                  value="Ingresar"
                ></input>
                :

                <img src={cargando} id="cargando" alt="Loading..." />
            }

            <Link id="reset-password" to="/ForgotPassword">
              Olvidé mi contraseña
            </Link>

            <Link to="/">
            <i className="fas fa-arrow-circle-left"></i> Regresar
            </Link>
            <br></br>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
