import React, { Fragment, useState, useEffect } from 'react';
import './QuienesSomos.css';
import { Link } from 'react-router-dom';
import piscina from '../../imagenes/piscina.jpeg';
import axios from 'axios';
import img_proyecto from '../../imagenes/img_proyecto.jpg';
import img_construccion from '../../imagenes/img_construccion.jpg';
import img_supervision from '../../imagenes/img_supervision.jpg';

import { Helmet } from "react-helmet";

const QuienesSomos = () => {

    const baseUrl = window.$baseUrl;
    const [data, setData] = useState([]);

    const peticionGet = async () => {
        await axios.get(baseUrl + "/api/todos/diferenciadores")
            .then(response => {
                setData(response.data);
            }).catch(error => {
                console.log(error);
            })
    }


    useEffect(() => {
        peticionGet();
    }, [])


    return (
        <Fragment>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Constructora - Grupo Citra | Mérida, Yucatán</title>
                <link rel="canonical" href="https://www.grupocitra.com.mx" />
                <meta name="description" content="Somos una constructora con más de 15 años de expericia ubicada en Mérida, Yucatán, especialistas en el desarrollo de proyectos, estudios de impacto vial y supervisiones." />
                <meta name="robots" content="noindex" />
            </Helmet>


            <div className="contenido-quienes-somos">

                <div className="contenido-imagenes-quienes-somos">
                    <div data-aos="slide-left">
                        <img src={piscina} alt="piscina" />
                    </div>
                </div>


                <div className="contenido-detalle-quienes-somos">
                    <div className="contenido-detalle-quienes-somos-contenido">
                        <h2 data-aos="fade-up">QUIÉNES SOMOS</h2>
                        <br></br>   <br></br>
                        <h3 data-aos="fade-down-right">Nuestra Filosofía</h3>
                        <br></br>
                        <p data-aos="fade-down" >Una empresa regional, de proyectos de construcción y supervisión comprometida con los retos de la sociedad.</p>
                        <a href="https://api.whatsapp.com/message/L2OKG5XKUZBNG1" target="blank">
                            <div data-aos="zoom-out-up" className="btn-whatsapp">
                                <i className="fab fa-whatsapp"></i> <label>Solicitar cotización</label>
                            </div>
                        </a>
                    </div>


                </div>

            </div>

            <div data-aos="fade-up" className="contenedor-quienes-somos-garantia">
                <p>¡Con <strong>Citra S.A. de C.V.</strong> tú inversión está 100% garantizada!</p>
            </div>


            <div className="contenedor-servicios-titulo">
                <h2>SERVICIOS</h2>
                <p>Ofrecemos una amplia gama de servicios relacionados con la construcción, supervisión y remodelación, estamos altamente capacitados para satisfacer altos estándares en requerimientos.</p>
            </div>


            <div className="contenedor-todos-servicios">

                <div data-aos="fade-up" className="contenedor-servicios-items">
                    <Link to="/proyectos">
                        <img src={img_proyecto} title="Proyectos" alt="Proyectos" />
                        <h3>Proyectos</h3>
                        <br></br>
                        <h4 title="Ir" >Conoce más</h4>
                    </Link>

                </div>

                <div data-aos="fade-up" className="contenedor-servicios-items">
                    <Link to="/construcciones">
                        <img src={img_construccion} title="Construcción" alt="Construcción" />
                        <h3>Construcción</h3>
                        <br></br>
                        <h4 title="Ir" >Conoce más</h4>
                    </Link>
                </div>

                <div data-aos="fade-up" className="contenedor-servicios-items">
                    <Link to="/supervisiones">
                        <img src={img_supervision} title="Supervisión" alt="Supervisión" />
                        <h3>Supervisión</h3>
                        <br></br>
                        <h4 title="Ir" >Conoce más</h4>
                    </Link>
                </div>
            </div>


            <div className="contenido-mision-vision">

                <div className="contenido-mision">
                    <div className="contenido-mision-datos">
                        <h2 data-aos="flip-down" >MISIÓN</h2>
                        <p data-aos="flip-down">Nuestra misión es ofrecer un valor agregado a sus proyectos y hacerlo realidad con los mas altos estándares en seguridad calidad y puntualidad.</p>
                        <br></br>
                    </div>

                    <div data-aos="slide-left" className="contenido-mision-imagen">
                        <img src="https://image.freepik.com/foto-gratis/planes-construccion-casco-amarillo-herramientas-dibujo-bluep_1232-2940.jpg" alt="misión" />
                    </div>


                </div>


                <div className="contenido-vision">
                    <div data-aos="slide-right" className="contenido-vision-imagen">
                        <img src="https://image.freepik.com/foto-gratis/ingeniero-sombrero-duro-trabajador-construccion-profesional-seguridad-trabajo-industria-edificio-persona-gerente-servicio_10541-902.jpg" alt="vision" />
                    </div>

                    <div className="contenido-vision-datos">
                        <h2 data-aos="flip-down" >VISIÓN</h2>
                        <p data-aos="flip-down" >Ser de las empresas número uno en la elaboración de estudios, proyectos y construcciones de acuerdo a las necesidades, con el objetivo solidario de ser más rentables y competitivas ante el desarrollo de la sociedad y la economía, siendo amigable con el medio ambiente.</p>
                    </div>
                </div>

            </div>

            {/* <div className="contenedor-valores">
                <div className="fondo-contenedor-valores">

                    <div className="contenedor-valores-titulo">
                        <h2>Valores</h2>
                    </div>

                    <div className="contenedor-valores-articulos">
                        <h3>Calidad</h3>
                        <h3>Puntualidad</h3>
                        <h3>Responsabilidad</h3>
                    </div>
                    <div>
                        <img src={valores} alt="vision" />
                        <br></br>
                    </div>

                </div>
            </div> */}

            <div className="contenido-como-diferenciamos-principal">
                <div className="contenido-como-diferenciamos-titulo">
                    <h2 data-aos="fade-down" >¡TÚ LO IMAGINAS, NOSOTROS LO CREAMOS!</h2>
                    <p data-aos="fade-up" >Déjate sorprender con todas las posibilidades que tenemos para tí.</p>
                </div>



                <div className="contenedor-como-diferenciamos-items">
                    {data.map(items =>
                        <div key={items.id_diferenciador} className="contenido-como-diferenciamos-item-izquierda">
                            <img data-aos="fade-down" alt="img" src={items.diferenciador_imagen} />
                            <h3 data-aos="slide-up" >{items.diferenciador_titulo}</h3>
                            <p data-aos="fade-right">{items.diferenciador_descripcion}</p>
                        </div>

                        // <div className="contenido-como-diferenciamos-item-derecha">
                        //     <img data-aos="fade-up" alt="img" src={items.diferenciador_imagen} />
                        //     <h3 data-aos="slide-up"  >MAQUINARIA PESADA</h3>
                        //     <p data-aos="fade-left" >Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen. No sólo sobrevivió 500 años, sino que tambien ingresó como texto de relleno en documentos electrónicos, quedando esencialmente igual al original. Fue popularizado en los 60s con la creación de las hojas "Letraset", las cuales contenian pasajes de Lorem Ipsum, y más recientemente con software de autoedición, como por ejemplo Aldus PageMaker, el cual incluye versiones de Lorem Ipsum.</p>
                        // </div> 
                    )}
                </div>

            </div>



        </Fragment>
    )
}


export default QuienesSomos;