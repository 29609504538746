import React, { Fragment, useState, useEffect } from 'react';
import './Clientes.css';
import NavBar from '../navbar/NavBar';
import Footer from '../footer/Footer';
import ContentLoader from "react-content-loader";
import axios from 'axios';
import fondo_clientes from '../../imagenes/fondo_clientes.jpg';

import { Helmet } from "react-helmet";

const Clientes = () => {

     const [data, setData] = useState([]);

     const baseUrl = window.$baseUrl;

     const peticionGet = async () => {
          await axios.get(baseUrl + "/api/todos/clientes")
               .then(response => {
                    setData(response.data);
               }).catch(error => {
                    console.log(error);
               })
     }


     useEffect(() => {
          peticionGet();
     }, [])




     return (
          <Fragment>

               <Helmet>
                    <meta charSet="utf-8" />
                    <title>Clientes</title>
                    <link rel="canonical" href="https://www.grupocitra.com.mx/clientes" />
                    <meta name="description" content="A lo largo de nuestra trayectoria Citra S.A. de C.V. ha ganado la confianza de clientes y empresas tanto locales, nacionales como internacionales brindando sus servicios con los más altos estándares de servicio y calidad del mercado." />
                    <meta name="robots" content="noindex" />
               </Helmet>

               <NavBar />
               <div data-aos="flip-up" className="contenedor-proyectos-titulo">
                    <img src={fondo_clientes} alt="fondo proyecto" title="fondo proyecto" />
                    <div className="proyectos-titulo">
                         <h1>Clientes</h1>
                         <h3>"Nuestros clientes satisfechos todos los días"</h3>
                    </div>
               </div>

               <div data-aos="slide-up" className="contenedor-proyectos-info">
                    <p id="clientes-p" >A lo largo de nuestra trayectoria <strong>Citra S.A. de C.V.</strong> ha ganado la confianza de clientes y empresas tanto locales, nacionales como internacionales brindando sus servicios con los más altos estándares de servicio y calidad del mercado.</p>
               </div>

               <div className="contenido-clientes">


                    <div className="contenido-clientes-logos">


                         {
                              data.map(items =>

                                   <div data-aos="flip-left" key={items.id_cliente} className="logos">
                                        <img src={items.logo_empresa} alt="logo" />
                                   </div>
                              )}

                         <div className="contenedor-clientes-precarga">

                              {data.length === 0 ?

                                   <ContentLoader
                                        speed={1}
                                        width={'100%'}

                                        viewBox="0 0 600 150"
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#ecebeb"

                                   >

                                        <rect x="395" y="84" rx="0" ry="0" width="0" height="1" />
                                        <rect x="19" y="9" rx="0" ry="0" width="173" height="110" />
                                        <rect x="204" y="9" rx="0" ry="0" width="174" height="110" />
                                        <rect x="390" y="7" rx="0" ry="0" width="182" height="110" />
                                   </ContentLoader>

                                   : ""}

                         </div>


                    </div>




               </div>




               <Footer />
          </Fragment>
     )
}

export default Clientes;