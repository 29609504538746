import React, { Fragment, useState, useEffect } from 'react';
import './Profile.css';
import fotoperfil from '../../imagenes/ingeniero.png';
import NavBarAdmin from '../../reutilizables/navBar/NavBarAdmin';
import Asidebar from '../../reutilizables/asidebar/Asidebar';
import { Redirect } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const Profile = () => {

    const baseUrl = window.$baseUrl;

    let id_usuario = cookies.get("id_usuario");


    const [cambiarP, setCambiarP] = useState(false);
    const [data, setData] = useState([]);
    const [contrasenaSeleccionado, setContrasenaSeleccionado] = useState({
        id_usuario: "",
        nombre_completo_usuario: "",
        correo: "",
        contrasena: "",
        id_cargo: ""
    });

    const cambiarContrasena = () => {
        setCambiarP(!cambiarP);
    }

    const handleChange = e => {
        const { name, value } = e.target;
        setContrasenaSeleccionado((prevState) => ({
            ...prevState,
            [name]: value
        }))

    }

    const peticionGet = async () => {
        await axios.get(baseUrl + "/api/usuario/" + id_usuario)
            .then(response => {
                setData(response.data);
            }).catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        peticionGet();
    }, [])


    const peticionPut = async (e) => {
        e.preventDefault();
        await axios.put(baseUrl + "/api/usuarios/" + id_usuario, {
            id_usuario: id_usuario,
            nombre_completo_usuario: data.nombre_completo_usuario,
            correo: data.correo,
            contrasena: contrasenaSeleccionado.contrasena,
            id_cargo: data.id_cargo

        })
            .then(response => {
                console.log(response);
                Swal.fire({
                    title: "¡Contraseña Actualizada!",
                    text: "Tú contraseña fue actualizada exitosamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                    timer: "5000"
                });
            })
            .catch(error => {
                console.log(error);
            });

        cambiarContrasena();

    }


    const [abrirMenu, setAbrirMenu] = useState(false);

    const abrirmenu = () => {
        setAbrirMenu(!abrirMenu);
    }

    const agrandarDashboard = {

    }

    const encogerDashboard = {
        display: 'flex'
    }





    return (
        <Fragment>
            {!cookies.get('correo') && <Redirect to="./" />}

            <div className="contenedor-principal-dashboard">

                <div className="contenido-dashboard-asidebar">

                    <div style={abrirMenu ? encogerDashboard : agrandarDashboard} className="contenedor-dashboard-asidebar" >
                        <Asidebar />
                    </div>

                    <div className="contenedor-dashboard">
                        <div className="contenedor-dashboard-navbar">
                            <NavBarAdmin abrirMenu={abrirMenu} setAbrirMenu={setAbrirMenu} abrirmenu={abrirmenu} />
                        </div>

                        <div className="fondo-profile">

                            <div className="profile-content">

                                <div className="profile">
                                    <div className="cover_page">
                                        <img src="https://scontent.fmid2-1.fna.fbcdn.net/v/t1.0-9/91565302_2984256941612693_635636635614052352_n.png?_nc_cat=105&ccb=2&_nc_sid=e3f864&_nc_eui2=AeEJdP-cODPjv8o98DFRzkcWE5lk4aIDvb8TmWThogO9v9BBqzZL0uJZGkgxfBCkZPQbUWMMwT30p39hN2lrm6iZ&_nc_ohc=nGu4oJux3L4AX8GmM9w&_nc_ht=scontent.fmid2-1.fna&oh=2a892b3057b619b7726a49fd47c7e6e4&oe=602EC593" alt="Portada de perfil" />
                                    </div>
                                    <div className="profile_picture">
                                        <img src={fotoperfil} alt="Foto de perfil" />
                                    </div>
                                    <div className="username">
                                        <h1> {cookies.get('nombre_completo_usuario')} </h1>
                                    </div>
                                    <hr />

                                </div>

                                <div className="main_profile_content">
                                    <div className="user_data">
                                        <h3 id="detalles-perfil">Detalles</h3>
                                        <br />
                                        <div className="content-data-profile">
                                            <i id="blanco" className="fas fa-briefcase"></i><h5>Grupo Citra S.A DE C.V.</h5>
                                        </div>
                                        <div className="content-data-profile">
                                            <i id="blanco" className="fas fa-envelope-open-text"></i><h5>{cookies.get('correo')}</h5>
                                        </div>
                                        <div className="content-data-profile">
                                            <i id="blanco" className="fas fa-key"></i><h5>**************</h5>
                                        </div>
                                        {cambiarP ?
                                            <form autocomplete="off" onSubmit={peticionPut}>
                                                <input id="input_nContrasena" onChange={handleChange} type="text" name="contrasena" placeholder="Escribe tu nueva contraseña" required ></input>
                                                <div className="content-btn-nC">
                                                    <input id="g_nueva_c" type="submit" value="Guardar"></input>
                                                    <input id="c_nueva_c" type="button" value="Cancelar" onClick={cambiarContrasena}></input>
                                                </div>
                                            </form>

                                            :
                                            <input type="button" id="nPass" value="Cambiar Contraseña" onClick={cambiarContrasena}></input>
                                        }
                                    </div>

                                    <div className="content-borrowed_books">
                                        <div id="ventas_mes" className="borrowed_books">
                                            <h3 id="fecha_corte"></h3>


                                        </div>


                                        <div className="borrowed_books">
                                            <h3 id="fecha_corte"></h3>



                                        </div>
                                    </div>



                                </div>

                            </div>
                        </div>

                    </div>


                </div>

            </div>



        </Fragment>
    )
}

export default Profile;