import React, { Fragment, useState, useEffect } from 'react';
import './Usuarios.css';
import NavBarAdmin from '../../reutilizables/navBar/NavBarAdmin';
import Asidebar from '../../reutilizables/asidebar/Asidebar';
import ModalAltaUsuarios from '../../formularios/modalUsuarios/ModalAltaUsuarios';
import ModalEditarUsuarios from '../../formularios/modalUsuarios/ModalEditarUsuarios';
import { Redirect } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const Usuarios = () => {

    const baseUrl = window.$baseUrl;

    const [data, setData] = useState([]);
    const [modalInsertar, setModalInsertar] = useState(false);
    const [modalEditar, setModalEditar] = useState(false);
    const [modalEliminar, setModalEliminar] = useState(false);
    const [usuarioSeleccionado, setUsuarioSeleccionado] = useState({
        id_usuario: "",
        nombre_completo_usuario: "",
        correo: "",
        contrasena: "",
        id_cargo_usuario: ""
    });

    const [abrirMenu, setAbrirMenu] = useState(false);

    const handleChange = e => {
        const { name, value } = e.target;
        setUsuarioSeleccionado((prevState) => ({
            ...prevState,
            [name]: value
        }))

    }

    const abrirCerrarModalInsertar = () => {
        setModalInsertar(!modalInsertar);
        document.getElementById("Form-insertarU").reset();
    }

    const abrirCerrarModalEditar = () => {
        setModalEditar(!modalEditar);
    }

    const abrirCerrarModalEliminar = () => {
        setModalEliminar(!modalEliminar);
    }


    const seleccionarUsuario = (usuario, caso) => {
        setUsuarioSeleccionado(usuario);

        (caso === "Editar") ?
            abrirCerrarModalEditar() :
            abrirCerrarModalEliminar()
    }



    const peticionGet = async () => {
        await axios.get(baseUrl + "/api/todos/usuario")
            .then(response => {
                setData(response.data);
            }).catch(error => {
                console.log(error);
            })
    }


    const peticionPost = async (e) => {

        let caracteres = "abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ2346789";
        let GenerarContrasena = "";
        for (let i = 0; i < 20; i++) GenerarContrasena += caracteres.charAt(Math.floor(Math.random() * caracteres.length));


        e.preventDefault();


        await axios.post(baseUrl + "/api/usuarios", {
            nombre_completo_usuario: usuarioSeleccionado.nombre_completo_usuario,
            correo: usuarioSeleccionado.correo,
            contrasena: GenerarContrasena,
            id_cargo: usuarioSeleccionado.id_cargo,
        }).then(response => {
            console.log(response);
            Swal.fire({
                icon: 'success',
                title: '¡Usuario agregado!',
                text: "Datos de acceso enviados al correo del usuario",
                confirmButtonText: 'Aceptar',
            });

        }).catch(error => {
            console.log(error);
        })

        peticionGet();
        enviarCredencialesUsuarios(GenerarContrasena);
        abrirCerrarModalInsertar();
    }

    const enviarCredencialesUsuarios = (GenerarContrasena) => {

        axios.post(baseUrl + '/api/usuarios/enviarcredenciales', {
            nombre: usuarioSeleccionado.nombre_completo_usuario,
            email: usuarioSeleccionado.correo,
            password: GenerarContrasena
        }).then(response => {

        }).catch(e => {
            console.log(e);
        });

    }


    const peticionPut = async (e) => {
        e.preventDefault();

        await axios.put(baseUrl + "/api/usuarios/" + usuarioSeleccionado.id_usuario, {

            id_usuario: usuarioSeleccionado.id_usuario,
            nombre_completo_usuario: usuarioSeleccionado.nombre_completo_usuario,
            correo: usuarioSeleccionado.correo,
            contrasena: usuarioSeleccionado.contrasena,
            id_cargo: usuarioSeleccionado.id_cargo,

        })
            .then(response => {
                console.log(response);
                peticionGet();
                Swal.fire({
                    icon: 'success',
                    title: '¡Usuario actualizado!',
                    confirmButtonText: 'Aceptar',
                });
               
            })
            
            .catch(error => {
                console.log(error);
            });



        peticionGet();

        abrirCerrarModalEditar();
    }


    const peticionDelete = (id) => {

        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡Una vez eliminado no podrás recuperar la información!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(baseUrl + "/api/usuarios/" + id, {

                }).then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: '¡Usuario eliminado!',
                        confirmButtonText: 'Aceptar',
                    });

                    peticionGet();
                }).catch(e => {
                    console.log(e);
                });


            }
        })

    }

    const abrirmenu = () => {
        setAbrirMenu(!abrirMenu);
    }

    const agrandarDashboard = {
       
    }

    const encogerDashboard = {
        display:'flex'
    }



    useEffect(() => {
        peticionGet();
    }, [])




    return (
        <Fragment>
            {!cookies.get('correo') && <Redirect to="./" />}

            {modalEditar ?
                <ModalEditarUsuarios usuarioSeleccionado={usuarioSeleccionado} seleccionarUsuario={seleccionarUsuario} peticionPut={peticionPut} modalInsertar={modalInsertar} peticionPost={peticionPost} modalEditar={modalEditar} abrirCerrarModalEditar={abrirCerrarModalEditar} abrirCerrarModalInsertar={abrirCerrarModalInsertar} handleChange={handleChange} />
                :
                <ModalAltaUsuarios usuarioSeleccionado={usuarioSeleccionado} seleccionarUsuario={seleccionarUsuario} peticionPut={peticionPut} modalInsertar={modalInsertar} peticionPost={peticionPost} modalEditar={modalEditar} abrirCerrarModalEditar={abrirCerrarModalEditar} abrirCerrarModalInsertar={abrirCerrarModalInsertar} handleChange={handleChange} />
            }

            <div className="contenedor-principal-dashboard">

                <div className="contenido-dashboard-asidebar">

                    <div style={abrirMenu ? encogerDashboard : agrandarDashboard} className="contenedor-dashboard-asidebar" >
                        <Asidebar />
                    </div>

                    <div className="contenedor-dashboard">
                        <div className="contenedor-dashboard-navbar">
                            <NavBarAdmin abrirMenu={abrirMenu} setAbrirMenu={setAbrirMenu} abrirmenu={abrirmenu} />
                        </div>

                        <div className="contenedor-principal-cargos">
                            <div className="contenedor-cargos-agregar">
                                <button onClick={() => abrirCerrarModalInsertar()}> <i className="fas fa-plus"> </i>  Agregar Usuario</button>
                            </div>

                            <div className="contenedor-tabla-cargos">
                                <table className="table">
                                    <thead className="thead">
                                        <tr>
                                            <th>#</th>
                                            <th>Nombre Completo</th>
                                            <th>Correo Electrónico</th>
                                            <th>Cargo</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody className="tbody">


                                        {data.map(items =>
                                            <tr key={items.id_usuario}>
                                                <td id="id_usuario" >{items.id_usuario}</td>
                                                <td id="nom_usuario" >{items.nombre_completo_usuario}</td>
                                                <td>{items.correo}</td>
                                                <td>{items.nombre_cargo}</td>
                                                <td id="botones-tabla">
                                                    <button onClick={() => seleccionarUsuario(items, "Editar")} id="editar" className="btn_editar" title="Editar"><i className="fas fa-pen"></i></button>

                                                    <button onClick={e => peticionDelete(items.id_usuario)} id="remover" className="btn_remover" title="Eliminar"><i className="fas fa-trash"></i></button>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>


                </div>

            </div>



        </Fragment>
    )
}

export default Usuarios;