import React, { Fragment } from 'react';
import { NavLink, Link } from 'react-router-dom';
import './Asidebar.css';
import logoCitraHorizontal from '../../imagenes/logo-citra-horizontal-negro.png';


const Asidebar = () => {



    return (
        <Fragment>
            <div className="contendor-principal-asidebar">
                <div className="contenedor-asidebar-logo">
                    <Link to="/dashboard"> <img src={logoCitraHorizontal} alt="Logo horizontal" /> </Link>
                </div>

                <div className="contenedor-asidebar-titulo-menu">
                    <h1>Menú</h1>
                </div>

                <div className="contenedor-asidebar-menu">
                    <NavLink to="/dashboard" activeClassName="asidebar-activo" className="contenedor-asidebar-menu-items">
                        <i className="fas fa-home"></i><h1>Inicio</h1>
                    </NavLink>
                    <NavLink to="/usuarios" activeClassName="asidebar-activo" className="contenedor-asidebar-menu-items">
                        <i className="fas fa-users"></i><h1>Usuarios</h1>
                    </NavLink>
                    <NavLink to="/agregarDiferenciador" activeClassName="asidebar-activo" className="contenedor-asidebar-menu-items">
                        <i className="fas fa-toolbox"></i><h1>Diferenciadores</h1>
                    </NavLink>
                    <NavLink to="/agregarClientes" activeClassName="asidebar-activo" className="contenedor-asidebar-menu-items">
                        <i className="fas fa-building"></i><h1>Clientes</h1>
                    </NavLink>
                    <NavLink to="/agregarProyectos" activeClassName="asidebar-activo" className="contenedor-asidebar-menu-items">
                        <i className="fas fa-folder-open"></i><h1>Proyectos</h1>
                    </NavLink>
                    <NavLink to="/cargos" activeClassName="asidebar-activo" className="contenedor-asidebar-menu-items">
                        <i className="fas fa-id-card-alt"></i><h1>Cargos</h1>
                    </NavLink>
                    <NavLink to="/profile" activeClassName="asidebar-activo"className="contenedor-asidebar-menu-items" >
                        <i className="fas fa-user"></i><h1>Perfil</h1>
                    </NavLink>
                </div>


            </div>

        </Fragment>
    )
}

export default Asidebar;