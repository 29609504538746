import React, { Fragment  } from "react";
import './ModalProyectos.css';



const ModalAltaProyectos = ({ modalEditar, modalInsertar, peticionPut, peticionPost, seleccionarProyecto, proyectoSeleccionado, abrirCerrarModalEditar, abrirCerrarModalInsertar, handleChange, handleChanges }) => {

  return (
    <Fragment>
      <div className={modalInsertar ? "Modal-desactive" : "Modal-active"}>
        <div className="contend-modalC">
          <div className="ModalHeaderC">
            <h2>Agregar Proyecto</h2>
            <i className="fas fa-times" onClick={() => abrirCerrarModalInsertar()} id="icon-close"></i>
          </div>
          <div className="ModalBodyC">
            <div className="FormGroupC">
              <form autoComplete="off" onSubmit={peticionPost} id="Form-insertarC" encType="multipart/form-data" >

                <label htmlFor="nombre_proyecto">Nombre proyecto</label>
                <input onChange={handleChange} name="nombre_proyecto" required type="text" />

                <label htmlFor="cliente_proyecto">Cliente</label>
                <input onChange={handleChange} name="cliente_proyecto" required type="text" />

                <label htmlFor="ubicacion_proyecto">Ubicación</label>
                <input onChange={handleChange} name="ubicacion_proyecto" required type="text" />

                <label htmlFor="duracion_proyecto">Duración</label>
                <input onChange={handleChange} name="duracion_proyecto" required type="text" />

                <label htmlFor="imagen_proyecto">Imagen</label>
                <input id="imagen_proyecto" onChange={handleChanges} required name="imagen_proyecto" accept="image/*" type="file" />


                <div className="ModalFooter">
                  <input id="yes" type="submit" value="Guardar" />
                  <button onClick={() => abrirCerrarModalInsertar()} id="cancel">Cancelar</button>
                </div>
              </form>



            </div>
          </div>
        </div>
      </div>



    </Fragment>
  );
}

export default ModalAltaProyectos;
