import React, { Fragment, useState, useEffect } from 'react';
import './Proyectos.css';
import NavBar from '../navbar/NavBar';
import Footer from '../footer/Footer';
import fondo_proyectos from '../../imagenes/fondo_proyectos.jpg';
import topo6 from '../../imagenes/proyectos/topo6.jpeg';
import carretera1 from '../../imagenes/proyectos/carretera1_5.jpg';
import aforo2 from '../../imagenes/proyectos/aforo2.JPG';

import { Helmet } from "react-helmet";


const Proyectos = () => {


    return (

        <Fragment>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Proyectos</title>
                <link rel="canonical" href="https://www.grupocitra.com.mx/proyectos" />
                <meta name="description" content="Elaboramos proyectos desde estudios costo beneficio, aforos, topografía con un equipo altamente capacitado." />
                <meta name="robots" content="noindex" />
            </Helmet>

            <NavBar />
            <div data-aos="flip-up" className="contenedor-proyectos-titulo">
                <img src={fondo_proyectos} alt="fondo proyecto" title="fondo proyecto" />
                <div className="proyectos-titulo">
                    <h1>Proyectos</h1>
                    <h3>"Elaboramos sus proyectos para hacer realidad sus sueños"</h3>
                </div>
            </div>

            <div data-aos="slide-up" className="contenedor-proyectos-info">
                <p>Elaboramos proyectos desde estudios costo beneficio, aforos, topografía con un equipo altamente capacitado.</p>
            </div>

            <div data-aos="zoom-out-up" className="contenedor-proyectos-items">
                <p>Servicios que ofrecemos en el área de proyectos:</p>
                <li>Estudio de impacto vial (Estudio de tránsito, aforos vehículares).</li>
                <li>Estudio de demanda.</li>
                <li>Encuestas origen destino.</li>
                <li>Levantamiento topográfico por estación total y/o dron.</li>
                <li>Análisis costo beneficio.</li>
                <li>Manifiesto de impacto ambiental</li>
                <li>Análisis financiero.</li>
                <li>Proyectos ejecutivos (Carreteras, agua potable, energia eléctrica).</li>
                <li>Análisis de alternativas.</li>
                <li>Estudio de impacto urbano.</li>
            </div>

            <div data-aos="zoom-out-up" className="contenedor-proyectos-imagenes">
                <img src={topo6} alt="topofrafía" title="topofrafía" />
                <img src={aforo2} alt="Aforo" title="Aforo" />
                <img src={carretera1} alt="supervision" title="supervision" />
            </div>




            <Footer />
        </Fragment>



    )
}

export default Proyectos;