import React, { Fragment, useState, useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom';
import './Dashboard.css';
import axios from 'axios';
import NavBarAdmin from '../../reutilizables/navBar/NavBarAdmin';
import Asidebar from '../../reutilizables/asidebar/Asidebar';
import Cookies from 'universal-cookie';
import usuarios from '../../imagenes/usuarios.png';
import tienda from '../../imagenes/tienda.png';
import carpeta from '../../imagenes/carpeta.png';
import cargos from '../../imagenes/cargos.png';
import cajaherramientas from '../../imagenes/cajaherramientas.png';


const cookies = new Cookies();

const Dashboard = () => {

    const baseUrl = window.$baseUrl;

    const [cantUsuarios, setCantUsuarios] = useState([]);

    const [cantCargos, setCantCargos] = useState([]);

    const [cantProyectos, setCantProyectos] = useState([]);

    const [cantClientes, setCantClientes] = useState([]);

    const [cantDif, setCantDif] = useState([]);

    const [abrirMenu, setAbrirMenu] = useState(false);

    const cantidadUsuarios = async () => {
        await axios.get(baseUrl + "/api/usurs/all/root")
            .then(response => {
                setCantUsuarios(response.data);
            }).catch(error => {
                console.log(error);
            })
    }

    const cantidadCargos = async () => {
        await axios.get(baseUrl + "/api/cargos/all/root")
            .then(response => {
                setCantCargos(response.data);
            }).catch(error => {
                console.log(error);
            })
    }

    const cantidadProyectos = async () => {
        await axios.get(baseUrl + "/api/proyectos/all/root")
            .then(response => {
                setCantProyectos(response.data);
            }).catch(error => {
                console.log(error);
            })
    }

    const cantidadClientes = async () => {
        await axios.get(baseUrl + "/api/clientes/all/root")
            .then(response => {
                setCantClientes(response.data);
            }).catch(error => {
                console.log(error);
            })
    }

    const cantidadDiferenciadores = async () => {
        await axios.get(baseUrl + "/api/diferenciador/all/root")
            .then(response => {
                setCantDif(response.data);
            }).catch(error => {
                console.log(error);
            })
    }




    const abrirmenu = () => {
        setAbrirMenu(!abrirMenu);
    }

    const agrandarDashboard = {

    }

    const encogerDashboard = {
        display: 'flex'
    }

    useEffect(() => {
        cantidadUsuarios();
        cantidadCargos();
        cantidadProyectos();
        cantidadClientes();
        cantidadDiferenciadores();
    }, [])


    return (
        <Fragment>

            {!cookies.get('correo') && <Redirect to="./login" />}

            <div className="contenedor-principal-dashboard">

                <div className="contenido-dashboard-asidebar">

                    <div style={abrirMenu ? encogerDashboard : agrandarDashboard} className="contenedor-dashboard-asidebar" >
                        <Asidebar />
                    </div>

                    <div className="contenedor-dashboard">
                        <div className="contenedor-dashboard-navbar">
                            <NavBarAdmin abrirMenu={abrirMenu} setAbrirMenu={setAbrirMenu} abrirmenu={abrirmenu} />
                        </div>
                        <div className="contenedor-dashboard-titulo">
                            <h1>Bienvenido(a)</h1>


                        </div>


                        <div className="contenedor-dashboard-items">


                            <div className="contenedor-dashboard-item">
                                <div id="azulA" className="contenedor-dashboard-tarjeta-contenido">
                                    <div className="contenedor-dashboard-tarjeta-titulo-descripcion">
                                        <h3>{cantUsuarios}</h3>
                                        <h4>Usuarios registrados</h4>
                                    </div>

                                    <div className="contenedor-dashboard-tarjeta-imagen">
                                        <Link to="/usuarios">
                                            <img alt="usuario" src={usuarios} />
                                        </Link>
                                    </div>
                                </div>

                                <div id="azulB" className="contenedor-dashboard-tarjeta-ver-mas">
                                    <Link to="/usuarios"> <h5>Ver más <i className="fas fa-arrow-circle-right"></i> </h5> </Link>
                                </div>
                            </div>



                            <div className="contenedor-dashboard-item">
                                <div id="rojoA" className="contenedor-dashboard-tarjeta-contenido">
                                    <div className="contenedor-dashboard-tarjeta-titulo-descripcion">
                                        <h3>{cantDif}</h3>
                                        <h4>Diferenciadores registrados</h4>
                                    </div>

                                    <div className="contenedor-dashboard-tarjeta-imagen">
                                        <Link to="/agregarDiferenciador">
                                            <img alt="usuario" src={cajaherramientas} />
                                        </Link>
                                    </div>
                                </div>

                                <div id="rojoB" className="contenedor-dashboard-tarjeta-ver-mas">
                                    <Link to="/agregarDiferenciador">
                                        <h5>Ver más <i className="fas fa-arrow-circle-right"></i></h5>
                                    </Link>
                                </div>
                            </div>



                            <div className="contenedor-dashboard-item">
                                <div id="verdeA" className="contenedor-dashboard-tarjeta-contenido">
                                    <div className="contenedor-dashboard-tarjeta-titulo-descripcion">
                                        <h3>{cantClientes}</h3>
                                        <h4>Logotipos registrados</h4>
                                    </div>

                                    <div className="contenedor-dashboard-tarjeta-imagen">
                                        <Link to="/agregarClientes">
                                            <img alt="usuario" src={tienda} />
                                        </Link>
                                    </div>
                                </div>

                                <div id="verdeB" className="contenedor-dashboard-tarjeta-ver-mas">
                                    <Link to="/agregarClientes">
                                        <h5>Ver más <i className="fas fa-arrow-circle-right"></i></h5>
                                    </Link>

                                </div>
                            </div>

                            <div className="contenedor-dashboard-item">
                                <div id="naranjaA" className="contenedor-dashboard-tarjeta-contenido">
                                    <div className="contenedor-dashboard-tarjeta-titulo-descripcion">
                                        <h3>{cantProyectos}</h3>
                                        <h4>Proyectos registrados</h4>
                                    </div>

                                    <div className="contenedor-dashboard-tarjeta-imagen">
                                        <Link to="/agregarProyectos">
                                            <img alt="usuario" src={carpeta} />
                                        </Link>
                                    </div>
                                </div>

                                <div id="naranjaB" className="contenedor-dashboard-tarjeta-ver-mas">
                                    <Link to="/agregarProyectos"> <h5>Ver más <i className="fas fa-arrow-circle-right"></i></h5> </Link>
                                </div>
                            </div>

                            <div className="contenedor-dashboard-item">
                                <div id="moradoA" className="contenedor-dashboard-tarjeta-contenido">
                                    <div className="contenedor-dashboard-tarjeta-titulo-descripcion">
                                        <h3>{cantCargos}</h3>
                                        <h4>Cargos registrados</h4>
                                    </div>

                                    <div className="contenedor-dashboard-tarjeta-imagen">
                                        <Link to="/cargos">
                                            <img alt="usuario" src={cargos} />
                                        </Link>
                                    </div>
                                </div>

                                <div id="moradoB" className="contenedor-dashboard-tarjeta-ver-mas">

                                    <Link to="/cargos"> <h5>Ver más <i className="fas fa-arrow-circle-right"></i> </h5> </Link>
                                </div>
                            </div>


                        </div>
                        {/* 
                        <div className="contenedor-redes-sociales">
                            <div id="facebook" className="contenedor-redes-sociales-items">

                                <div className="contenedor-redes-sociales-logos"> 
                                        <img src={logoFacebook} alt="facebook" />
                                </div>

                                <div className="contenedor-redes-sociales-descripcion"> 
                                    <h3>230</h3>
                                    <h4>Seguidores</h4>
                                </div>

                            </div>

                            <div id="instagram" className="contenedor-redes-sociales-items">

                                <div className="contenedor-redes-sociales-logos"> 
                                        <img src={logoInstagram} alt="instagram" />
                                </div>

                                <div className="contenedor-redes-sociales-descripcion"> 
                                    <h3>200</h3>
                                    <h4>Seguidores</h4>
                                </div>

                            </div>


                        </div> */}

                    </div>


                </div>

            </div>
        </Fragment>
    )
}

export default Dashboard;