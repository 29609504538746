import React, { Fragment, useState, useEffect } from "react";
import './ModalUsuarios.css';
import axios from 'axios';



const ModalAltaUsuarios = ({ modalEditar, modalInsertar, peticionPut, peticionPost, seleccionarUsuario, usuarioSeleccionado, abrirCerrarModalEditar, abrirCerrarModalInsertar, handleChange }) => {

  const baseUrl = window.$baseUrl;

  const peticionC = async () => {
    await axios.get(baseUrl + "/api/todos/cargos")
      .then(response => {
        setCargoSeleccionado(response.data);
      }).catch(error => {
        console.log(error);
      })
  }



  useEffect(() => {
    peticionC();
  }, [])


  const [cargoSeleccionado, setCargoSeleccionado] = useState([]);


  return (
    <Fragment>
      <div className={modalInsertar ? "Modal-desactive" : "Modal-active"}>
        <div className="contend-modalU">
          <div className="ModalHeaderU">
            <h2>Agregar Usuario</h2>
            <i className="fas fa-times" onClick={() => abrirCerrarModalInsertar()} id="icon-close"></i>
          </div>
          <div className="ModalBodyU">
            <div className="FormGroupU">
              <form autoComplete="off" onSubmit={peticionPost} id="Form-insertarU">
                
                <label htmlFor="nombre_completo_usuario">Nombre completo</label>
                <input onChange={handleChange} name="nombre_completo_usuario" required type="text" />

                <label htmlFor="correo">Correo electrónico</label>
                <input onChange={handleChange} name="correo" required type="email" />

                <label hidden htmlFor="contrasena">Contraseña</label>
                <input hidden onChange={handleChange} id="contrasena" name="contrasena" type="text" />

                <label htmlFor="id_cargo">Cargo</label>
                <select onChange={handleChange} id="id_cargo" required name="id_cargo">
                  <option value="" defaultValue>Seleccione una opción</option>
                  {cargoSeleccionado.map(items =>
                    <option key={items.id_cargo} value={items.id_cargo}>{items.nombre_cargo}</option>
                  )}
                </select>

                <div className="ModalFooter">
                <input id="yes" type="submit" value="Guardar" />
                <button onClick={() => abrirCerrarModalInsertar()} id="cancel">Cancelar</button>
                </div>
              </form>
             


            </div>
          </div>
        </div>
      </div>



    </Fragment>
  );
}

export default ModalAltaUsuarios;
