import React, { Fragment } from "react";
import './ModalCargos.css';



const ModalAltaCargos = ({ modalEditar, modalInsertar, peticionPut, peticionPost, seleccionarCargo, cargoSeleccionado, abrirCerrarModalEditar, abrirCerrarModalInsertar, handleChange }) => {

  return (
    <Fragment>
      <div className={modalInsertar ? "Modal-desactive" : "Modal-active"}>
        <div className="contend-modalC">
          <div className="ModalHeaderC">
            <h2>Agregar Cargo</h2>
            <i className="fas fa-times" onClick={() => abrirCerrarModalInsertar()} id="icon-close"></i>
          </div>
          <div className="ModalBodyC">
            <div className="FormGroupC">
              <form autoComplete="off" onSubmit={peticionPost} id="Form-insertarC">
                
                <label htmlFor="nombre_cargo">Nombre cargo</label>
                <input onChange={handleChange} name="nombre_cargo" required type="text" />

                

                <div className="ModalFooter">
                <input id="yes" type="submit" value="Guardar" />
                <button onClick={() => abrirCerrarModalInsertar()} id="cancel">Cancelar</button>
                </div>
              </form>
             


            </div>
          </div>
        </div>
      </div>



    </Fragment>
  );
}

export default ModalAltaCargos;
