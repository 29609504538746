import React, { Fragment, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import "./NavBar.css";
import logohorizontal from "../../imagenes/logo-citra-horizontal.png";


const NavBar = () => {

    const [menu, setMenu] = useState(true);

    const abrirmenu = () => {
        setMenu(!menu);

    }

    const abrir = { zIndex: 3, transition: "0.6s", top: "80px" };
    const cerrar = { zIndex: 3, transition: "1s", top: "-400px" };



    return (
        <Fragment>
            <div className="contenedor-principal">

                <div data-aos="flip-right" className="contenedor-logo">

                    <Link to="/inicio">

                        <img onDoubleClick={() => { window.location.href = "/login" }} src={logohorizontal} width="180px" alt="logo citra" />
                    </Link>
                </div>


                <div data-aos="flip-down" className="contenedor-menu" >

                    <NavLink to="/inicio" activeClassName="menu-seleccionado">Inicio</NavLink>
                    <NavLink to="/proyectos" activeClassName="menu-seleccionado">Proyectos</NavLink>
                    <NavLink to="/construcciones" activeClassName="menu-seleccionado">Construcción</NavLink>
                    <NavLink to="/supervisiones" activeClassName="menu-seleccionado">Supervisión</NavLink>
                    <NavLink to="/clientes" activeClassName="menu-seleccionado">Clientes</NavLink>
                    <NavLink to="/contacto" activeClassName="menu-seleccionado">Contacto</NavLink>

                </div>


                <div data-aos="flip-left" className="contenedor-nav-redes-sociales">
                    <a href="https://www.facebook.com/GrupoCitraSaDeCv" title="Fan Page" target="blank" ><i className="fab fa-facebook"></i></a>
                    <a href="https://api.whatsapp.com/message/L2OKG5XKUZBNG1" title="WhatsApp" target="blank"><i className="fab fa-whatsapp"></i></a>
                </div>

                <div data-aos="flip-left" className="menu-movil">
                    <i onClick={abrirmenu} className={menu ? "fas fa-bars" : "fas fa-times"}></i>
                </div>
            </div>
            <div className="contenedor-menu-movil" >

                <nav style={menu ? cerrar : abrir}>
                    <NavLink onClick={window.scrollTo(0, 0)} to="/inicio" activeClassName="menu-seleccionado">Inicio</NavLink>
                    <NavLink to="/proyectos" activeClassName="menu-seleccionado">Proyectos</NavLink>
                    <NavLink to="/construcciones" activeClassName="menu-seleccionado">Construcción</NavLink>
                    <NavLink to="/supervisiones" activeClassName="menu-seleccionado">Supervisión</NavLink>
                    <NavLink to="/clientes" activeClassName="menu-seleccionado">Clientes</NavLink>
                    <NavLink to="/contacto" activeClassName="menu-seleccionado">Contacto</NavLink>

                    <div className="contenedor-redes-sociales-movil">
                        <a href="https://api.whatsapp.com/message/L2OKG5XKUZBNG1" title="WhatsApp" target="blank" > <i className="fab fa-whatsapp"></i>    </a>
                        <a href="https://www.facebook.com/GrupoCitraSaDeCv" title="Facebook" target="blank" > <i className="fab fa-facebook-f"></i>     </a>
                        <a href="https://www.facebook.com/messages/t/352408271464253" title="Messenger" target="blank" > <i className="fab fa-facebook-messenger"></i>    </a>
                        <a href="https://www.instagram.com/grupocitra/" title="Instagram" target="blank" > <i className="fab fa-instagram"></i>    </a>
                    </div>
                </nav>


            </div>
        </Fragment>
    )
}

export default NavBar;