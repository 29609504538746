import React, { Fragment, useState } from "react";
// import './ModalDiferenciador.css';



const ModalEditarDiferenciador = ({ modalEditar, modalInsertar, peticionPut, peticionPost, seleccionarCargo, proyectoSeleccionado, file, abrirCerrarModalEditar, abrirCerrarModalInsertar, handleChange, handleChanges }) => {

const [clic, setClic] = useState(false);

const cambiarClic = () =>{
  setClic(!clic)
}


  return (
    <Fragment>
      <div className={modalEditar ? "Modal-desactive" : "Modal-active"}>
        <div className="contend-modalC">
          <div className="ModalHeaderC">
            <h2>Editar Diferenciador</h2>
            <i className="fas fa-times" onClick={() => abrirCerrarModalEditar()} id="icon-close"></i>
          </div>
          <div className="ModalBodyC">
            <div className="FormGroupC">
              <form autoComplete="off" onSubmit={peticionPut} id="miFormC">

                <label htmlFor="diferenciador_titulo">Titulo</label>
                <input onChange={handleChange} name="diferenciador_titulo" value={proyectoSeleccionado && proyectoSeleccionado.diferenciador_titulo} required type="text" />
                
                <label htmlFor="diferenciador_descripcion">Descripción</label>
                <textarea rows="5" onChange={handleChange} name="diferenciador_descripcion" value={proyectoSeleccionado && proyectoSeleccionado.diferenciador_descripcion} required />

                <label htmlFor="diferenciador_imagen">Imagen</label>
                <br></br>
                <img onClick={cambiarClic} id="imagen_editar_proyecto" width="210px" src={file && file} alt="imagen"/>
                {
                  clic ? <input id="imagen_proyecto" onChange={handleChanges} name="diferenciador_imagen" accept="image/*" type="file" value={file && file.diferenciador_imagen} /> 
                  : 
                  ""
                }
                

                <div className="ModalFooter">
                  <input id="yes" type="submit" value="Guardar Cambios" />
                  <button onClick={() => abrirCerrarModalEditar()} id="cancel">Cancelar</button>
                </div>
              </form>




            </div>
          </div>
        </div>
      </div>

    </Fragment>
  );
}

export default ModalEditarDiferenciador;
