import React, { Fragment, useState } from 'react';
import './NavBarAdmin.css';
import { Link } from 'react-router-dom';
import fotoPerfil from '../../imagenes/ingeniero.png';
import logoCitraHorizontal from '../../imagenes/logo-citra-horizontal-negro.png';
import Cookies from 'universal-cookie';


const cookies = new Cookies();

const NavBarAdmin = ({ abrirmenu, abrirMenu, setAbrirMenu }) => {

    const cerrarSesion = () => {
        cookies.remove('id_usuario', { path: "/" });
        cookies.remove('nombre_completo_usuario', { path: "/" });
        cookies.remove('correo', { path: "/" });
        cookies.remove('nombre_cargo', { path: "/" });

        window.location.href = './login';
    }

    const [menu, setMenu] = useState(true);

    const cambiarmenu = () => {
        setMenu(!menu);

    }



    return (
        <Fragment>
            <div className="contenedor-principal-navbar">
                <div className="contenedor-navbar-accesos-directos">
                    <div onClick={abrirmenu} className="contenedor-navbar-menu">
                        <i onClick={cambiarmenu} className={menu ? "fas fa-bars" : "fas fa-times"}></i>
                    </div>


                </div>

                <div className="contenedor-navbar-logo">
                    <Link to="/dashboard"> <img src={logoCitraHorizontal} alt="logo horizontal" /> </Link>
                </div>


                <div className="contenedor-navbar-foto-perfil">
                    <ul className="nav">
                        <li>
                            <img src={fotoPerfil} alt="foto perfil" />
                            <ul>
                                <li>
                                    <div className="contenedor-navbar-datos-perfil">
                                        <h4>{cookies.get('nombre_completo_usuario')}</h4>
                                        <h5>{cookies.get('nombre_cargo')}</h5>

                                        <div className="btn-cerrar-sesion" onClick={cerrarSesion} ><i className="fas fa-sign-out-alt"></i> Cerrar Sesión</div>

                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>

        </Fragment>
    )
}

export default NavBarAdmin;