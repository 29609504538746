import React, { Fragment, useState, useEffect } from "react";
import './ModalUsuarios.css';
import axios from 'axios';


const ModalEditarUsuarios = ({ modalEditar, modalInsertar, peticionPut, peticionPost, seleccionarUsuario, usuarioSeleccionado, abrirCerrarModalEditar, abrirCerrarModalInsertar, handleChange }) => {

  const baseUrl = window.$baseUrl;


  const peticionC = async () => {
    await axios.get(baseUrl + "/api/todos/cargos")
    .then(response => {
      setCargoSeleccionado(response.data);
    }).catch(error => {
      console.log(error);
    })
  }

  

  useEffect(() => {
    peticionC();
  }, [])


  const [cargoSeleccionado, setCargoSeleccionado] = useState([]);
  const [cargoS, setCargoS] = useState(false);


  const cargo = usuarioSeleccionado && usuarioSeleccionado.nombre_cargo;




  return (
    <Fragment>
      <div className={modalEditar ? "Modal-desactive" : "Modal-active"}>
        <div className="contend-modalU">
          <div className="ModalHeaderU">
            <h2>Editar Usuario</h2>
            <i className="fas fa-times" onClick={() => abrirCerrarModalEditar()} id="icon-close"></i>
          </div>
          <div className="ModalBodyU">
            <div className="FormGroupU">
              <form autocomplete="off" onSubmit={peticionPut} id="miFormU">

                <label htmlFor="nombre_completo_usuario">Nombre completo</label>
                <input onChange={handleChange} name="nombre_completo_usuario" value={usuarioSeleccionado && usuarioSeleccionado.nombre_completo_usuario} required type="text" />

                <label htmlFor="correo">Correo electrónico</label>
                <input onChange={handleChange} name="correo" value={usuarioSeleccionado && usuarioSeleccionado.correo} required type="email" />

                <label hidden htmlFor="contrasena">Contraseña</label>
                <input hidden onChange={handleChange} id="contrasena" name="contrasena" value={usuarioSeleccionado && usuarioSeleccionado.contrasena} required type="password" />

                <label htmlFor="id_cargo">Cargo</label>
                {cargoS ?
                  <select onChange={handleChange} id="id_cargo" required name="id_cargo">
                    <option value="" defaultValue>Seleccione una opción</option>
                    {cargoSeleccionado.map(items =>
                      <option key={items.id_cargo} value={items.id_cargo} >{items.nombre_cargo}</option>
                    )}
                  </select>
                  :
                  <input onClick={() => setCargoS(true)} onChange={handleChange} id="id_cargo" name="id_cargo" value={cargo} required type="text" />
                }



                <div className="ModalFooter">
                  <input id="yes" type="submit" value="Guardar Cambios" />
                  <button onClick={() => abrirCerrarModalEditar()} id="cancel">Cancelar</button>
                </div>
              </form>




            </div>
          </div>
        </div>
      </div>

    </Fragment>
  );
}

export default ModalEditarUsuarios;
