import React, { Fragment, useState } from "react";
import logo_email from "../../imagenes/email.png";
import Header from "../../reutilizables/header/Header";
import Footer from "../../reutilizables/footer/Footer";
import loading from "../../imagenes/loading.gif";
import "./ForgotPassword.css";
import Swal from 'sweetalert2';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { Link, Redirect } from "react-router-dom";


const ForgotPassword = () => {

  const baseUrl = window.$baseUrl;

  const cookies = new Cookies();

  const [email_input, setEmail_input] = useState({
    email: "",
  });

  const [enviado, setEnviado] = useState(true);

  const stylesde = { background:"rgb(99, 125, 245)", color:"rgb(219, 219, 219)", cursor: "context-menu" };
  const stylesac = {  };

  const handleChange = (event) => {
    setEmail_input({
      ...email_input,
      [event.target.name]: event.target.value,
    });
  };


  const recuperarContrasena = async (e) => {
    e.preventDefault();

    if(email_input.email.length <= 0) {
      Swal.fire({
            title: "¡Campo vacio!",
            text: "¡Ingresa tu correo electrónico!",
            icon: "warning",
            button: "Aceptar",
            timer: "3000"
        });
    } else{      
      setEnviado(false);
      
      
      await axios.get(baseUrl + "/api/usuarios/" + email_input.email)
        .then(response => {

          const savedata = response.data;
          const datauser = (Object.keys(response.data).length);

            if (datauser > 0) {

              let pass = savedata[0].contrasena;
              let nom_usu = savedata[0].nombre_completo_usuario;
                
                axios.post(baseUrl + '/api/usuarios/resetpass', {  
                    nombre: nom_usu,
                    email: email_input.email,
                    password: pass
                }).then(response => {
                  setEnviado(true);
                  Swal.fire({
                    title: "¡Contraseña enviada!",
                    text: `¡La contraseña fue enviado a: ${email_input.email}!, sino encuentra el correo verifica en sección de correos no deseados o Spam`,
                    icon: "success",
                    confirmButtonText: "Aceptar",
                    timer: "6000"
                });
                }).catch(e => {
                    console.log(e);
                });
                

            } else {
              setEnviado(true);
              Swal.fire({
                    title: "¡Aviso!",
                    text: "¡El correo ingresado no se encuentra registrado!",
                    icon: "warning",
                    confirmButtonText: "Aceptar",
                    timer: "4000"
                });

            }

        })
        
        .catch(error => {
            console.log(error);
        })
    }

   

    

}


  return (
    <Fragment>
      {cookies.get('correo') && <Redirect to="./dashboard" />} 
      <Header />
      <div className="fondo-forgot-password">
        <div className="content-forgot-password">
          <form onSubmit={recuperarContrasena} method="#" className="form">

          {enviado ? <img src={logo_email} alt="Logo_email" /> : <img src={loading} id="loading" alt="Loading..." />}
            
            <h1>¿Olvidaste tu contraseña?</h1>
            <p>
              ¡Ingresa el correo electrónico que usaste al momento de{" "}
              registrarte para recuperar tu contraseña!
            </p>
            <label id="label">Correo</label>
            
            <input 
              id="email"
              name="email"
              type="email"
              placeholder="Correo electrónico"
              required
              onChange={handleChange}
            />
           
            <input style={enviado ? stylesac : stylesde} onClick={recuperarContrasena} disabled={enviado ? "" : "true"} id="send" type="submit" value="Enviar" />
          </form>
        </div>
      </div>
      <footer className="footer">
        <Link to="/Login">
          <h3>
            {" "}
            <i className="fas fa-arrow-left"></i>Regresar
          </h3>
        </Link>
      </footer>
      <Footer />
    </Fragment>
  );
};

export default ForgotPassword;
