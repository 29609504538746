import React, { Fragment, useState } from 'react';
import './Contacto.css';
import axios from 'axios';
import NavBar from '../navbar/NavBar';
import Footer from '../footer/Footer';
import logoCitra from '../../imagenes/logoCitra.png';
import cargando from '../../imagenes/cargando.gif';
import ReCAPTCHA from "react-google-recaptcha";
import Swal from 'sweetalert2';

import { Helmet } from "react-helmet";


const Contacto = () => {

    const baseUrl = window.$baseUrl;


    const [enviado, setEnviado] = useState(true);

    const [datos, setDatos] = useState({

        nombre: "",
        numero: "",
        correo: "",
        mensaje: ""

    });


    const handleChange = (event) => {
        setDatos({
            ...datos,
            [event.target.name]: event.target.value,
        });
    };


    const enviarMensaje = async (e) => {
        e.preventDefault();

        if (datos.nombre.length <= 0) {
            Swal.fire({
                icon: 'warning',
                title: '¡Campo vacío!',
                text: "Ingrese su nombre completo",
                confirmButtonText: `Aceptar`,
            })

        } else if (datos.numero.length <= 0) {
            Swal.fire({
                icon: 'warning',
                title: '¡Campo vacío!',
                text: "Ingrese su número",
                confirmButtonText: `Aceptar`,
            })
        } else if (datos.correo.length <= 0) {
            Swal.fire({
                icon: 'warning',
                title: '¡Campo vacío!',
                text: "Ingrese su correo electrónico",
                confirmButtonText: `Aceptar`,
            })
        } else if (datos.mensaje.length <= 0) {
            Swal.fire({
                icon: 'warning',
                title: '¡Campo vacío!',
                text: "Ingrese su mensaje",
                confirmButtonText: `Aceptar`,
            })
        } else {
            setEnviado(false);

            axios.post(baseUrl + '/api/enviardatos', {
                nombre: datos.nombre,
                numero: datos.numero,
                correo: datos.correo,
                mensaje: datos.mensaje
            }).then(response => {
                setEnviado(true);

                Swal.fire({
                    icon: 'success',
                    title: '¡Mensaje enviado!',
                    text: "En breve nos pondremos en contacto contigo",
                    confirmButtonText: 'Aceptar',

                })
                    .then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = "/contacto";
                        }
                    })
            }).catch(e => {
                console.log(e);
            });

        }

    }


    return (
        <Fragment>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Contacto</title>
                <link rel="canonical" href="https://www.grupocitra.com.mx/contacto" />
                <meta name="description" content="Comunicarte con nosotros y dejate sorpender." />
                <meta name="robots" content="noindex" />
            </Helmet>

            <NavBar />
            <div className="prin">
                <div data-aos="zoom-out" className="contendor-contacto-titulo">
                    <h2>Contacto</h2>
                </div>

                <div className="contenido-contacto">

                    <div className="contenido-contacto-logo">
                        <img data-aos="flip-left" src={logoCitra} title="Grupo Citra" alt="logo Citra" />

                        <div data-aos="slide-down" className="contenido-contacto-direccion">
                            <i className="fas fa-map-marker-alt"></i>
                            <h2>Calle 33 A x 24 No. 373, Polígono 108, <br></br> Mérida, Yuc.</h2>
                        </div>

                        <div className="contenido-contacto-telefono-correo">
                            <div className="contenido-contacto-telefono">
                                <i className="fas fa-phone-volume"></i>
                                <a href="tel:9991295931" ><h2>999-129-5931</h2></a>
                            </div>

                            <div className="contenido-contacto-correo">
                                <i className="fas fa-mail-bulk"></i>
                                <a href="gerencia@grupocitra.com.mx"><h2>gerencia@grupocitra.com.mx</h2></a>
                            </div>
                        </div>


                    </div>

                    <div data-aos="zoom-in-up" className="contenido-contacto-formulario">
                        <h4>¡Contáctanos!</h4>
                        <form onSubmit={enviarMensaje} >
                            <input data-aos="flip-up" onChange={handleChange} placeholder="Nombre Completo" name="nombre" type="text" autoComplete="off" ></input>
                            <input data-aos="flip-up" onChange={handleChange} placeholder="Número Telefóno" name="numero" type="tel" maxLength="10" autoComplete="off" ></input>
                            <input data-aos="flip-up" onChange={handleChange} placeholder="Correo Electrónico" name="correo" type="email" autoComplete="off" ></input>
                            <textarea data-aos="flip-up" onChange={handleChange} rows="4" placeholder="Mensaje" name="mensaje" autoComplete="off" ></textarea>
                            <div data-aos="flip-up" className="contenedor-contacto-botones">
                                <div>
                                    <ReCAPTCHA

                                        sitekey="6LfA8FAaAAAAADRjsWGIcs9jCtBbyWz2ta7nXBkq"


                                    />
                                </div>

                                <div className="contenedor-botones">
                                    {
                                        enviado ? <input disabled={enviado ? "" : "true"} value="Enviar" type="submit" id="submit" /> : <img id="cargando" width="50px" alt="cargando" src={cargando}></img>
                                    }

                                </div>


                            </div>
                            {/* <Link to="/privacidad"><h5>*Aviso de privacidad</h5></Link> */}
                        </form>
                    </div>

                </div>

                <div data-aos="flip-down" className="contenedor-contacto-colaboradores-titulo">
                    <h2>Nuestro equipo</h2>
                </div>

                <div className="contenedor-contacto-colaboradores">

                    <div data-aos="slide-right" className="contenedor-colaboradores">
                        <strong>DRA. Nery del Socorro Escalante May</strong>
                        <br></br>
                    ----------Directora General----------
                    <br></br>
                    Fundadora de grupo citra, economista, emprendedora y empresaria con grandes cargos importantes en la industria de la construcción.
                    <br></br>
                        <a href="tel:9991295931">Tel: 999-129-5931</a>
                        <br></br>
                        <a href="mailto:citra.neryescalante@gmail.com">Correo: citra.neryescalante@gmail.com</a>
                    </div>
                    <div data-aos="slide-left" className="contenedor-colaboradores">
                        <strong>ING. Luis Alfredo Flores Escalante</strong>
                        <br></br>
                    ---Gerente de obras y proyectos---
                    <br></br>
                    Ingeniero encargado de la supervisión de las obras y proyectos que se realizan.
                    <br></br>
                        <a href="tel:9995994542">Tel: 999-599-4542</a>
                        <br></br>
                        <a href="mailto:citra.alfredoflores@gmail.com">Correo: citra.alfredoflores@gmail.com</a>
                    </div>

                </div>

                <div data-aos="zoom-in-up" className="contenedor-mapa">
                    <iframe title="Mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3725.110708137307!2d-89.57675355061076!3d20.988198894471555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f5670cf18011f9f%3A0xc501d23123471d2f!2sCITRA%20S.A.%20DE%20C.V.!5e0!3m2!1ses-419!2smx!4v1610661563193!5m2!1ses-419!2smx" width="96%" height="500" frameBorder="0" style={{ border: 0 }} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                </div>
            </div>
            <Footer />
        </Fragment>
    )
}

export default Contacto;