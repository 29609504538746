import React, {Fragment} from 'react';
import './Header.css';
import {Link} from 'react-router-dom';

const Header = () =>{
    return(
        <Fragment>
            <div className="content-header">
                <Link to="/" >Grupo Citra S.A DE C.V.</Link>
            </div>
        </Fragment>
    );  
}

export default Header;