import React, { Fragment  } from "react";
// import './ModalDiferenciador.css';


const ModalAltaDiferenciador = ({ modalEditar, modalInsertar, peticionPut, peticionPost, seleccionarProyecto, proyectoSeleccionado, abrirCerrarModalEditar, abrirCerrarModalInsertar, handleChange, handleChanges }) => {

  return (
    <Fragment>
      <div className={modalInsertar ? "Modal-desactive" : "Modal-active"}>
        <div className="contend-modalC">
          <div className="ModalHeaderC">
            <h2>Agregar Diferenciador</h2>
            <i className="fas fa-times" onClick={() => abrirCerrarModalInsertar()} id="icon-close"></i>
          </div>
          <div className="ModalBodyC">
            <div className="FormGroupC">
              <form autoComplete="off" onSubmit={peticionPost} id="Form-insertarC" encType="multipart/form-data" >

                <label htmlFor="diferenciador_titulo">Titulo</label>
                <input onChange={handleChange} name="diferenciador_titulo" required type="text" />

                <label htmlFor="diferenciador_descripcion">Descripción</label>
                <textarea rows="5" onChange={handleChange} name="diferenciador_descripcion" required />

                <label htmlFor="diferenciador_imagen">Imagen</label>
                <input id="imagen_proyecto" onChange={handleChanges} required name="diferenciador_imagen" accept="image/*" type="file" />


                <div className="ModalFooter">
                  <input id="yes" type="submit" value="Guardar" />
                  <button onClick={() => abrirCerrarModalInsertar()} id="cancel">Cancelar</button>
                </div>
              </form>



            </div>
          </div>
        </div>
      </div>



    </Fragment>
  );
}

export default ModalAltaDiferenciador;
