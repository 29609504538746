import React, { Fragment, useState } from "react";
import './ModalProyectos.css';



const ModalEditarProyectos = ({ modalEditar, modalInsertar, peticionPut, peticionPost, seleccionarCargo, proyectoSeleccionado, file, abrirCerrarModalEditar, abrirCerrarModalInsertar, handleChange, handleChanges }) => {

const [clic, setClic] = useState(false);

const cambiarClic = () =>{
  setClic(!clic)
}

  return (
    <Fragment>
      <div className={modalEditar ? "Modal-desactive" : "Modal-active"}>
        <div className="contend-modalC">
          <div className="ModalHeaderC">
            <h2>Editar Proyecto</h2>
            <i className="fas fa-times" onClick={() => abrirCerrarModalEditar()} id="icon-close"></i>
          </div>
          <div className="ModalBodyC">
            <div className="FormGroupC">
              <form autoComplete="off" onSubmit={peticionPut} id="miFormC">

                <label htmlFor="nombre_proyecto">Nombre proyecto</label>
                <input onChange={handleChange} name="nombre_proyecto" value={proyectoSeleccionado && proyectoSeleccionado.nombre_proyecto} required type="text" />
                
                <label htmlFor="cliente_proyecto">Cliente</label>
                <input onChange={handleChange} name="cliente_proyecto" value={proyectoSeleccionado && proyectoSeleccionado.cliente_proyecto} required type="text" />

                <label htmlFor="ubicacion_proyecto">Ubicación</label>
                <input onChange={handleChange} name="ubicacion_proyecto" value={proyectoSeleccionado && proyectoSeleccionado.ubicacion_proyecto} required type="text" />

                <label htmlFor="duracion_proyecto">Duración</label>
                <input onChange={handleChange} name="duracion_proyecto" value={proyectoSeleccionado && proyectoSeleccionado.duracion_proyecto} required type="text" />
                
                <label htmlFor="imagen_proyecto">Imagen</label>
                <br></br>
                <img onClick={cambiarClic} id="imagen_editar_proyecto" width="210px" src={file && file} alt="imagen"/>
                {
                  clic ? <input id="imagen_proyecto" onChange={handleChanges} name="imagen_proyecto" accept="image/*" type="file" value={file && file.imagen_proyecto} /> 
                  : 
                  ""
                }
                

                <div className="ModalFooter">
                  <input id="yes" type="submit" value="Guardar Cambios" />
                  <button onClick={() => abrirCerrarModalEditar()} id="cancel">Cancelar</button>
                </div>
              </form>




            </div>
          </div>
        </div>
      </div>

    </Fragment>
  );
}

export default ModalEditarProyectos;
