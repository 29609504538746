import React, { Fragment } from "react";
import './ModalClientes.css';


const ModalAltaClientes = ({file, modalEditar, modalInsertar, peticionPut, peticionPost, seleccionarProyecto, proyectoSeleccionado, abrirCerrarModalEditar, abrirCerrarModalInsertar, handleChange, handleChanges }) => {

  return (
    <Fragment>
      <div className={modalInsertar ? "Modal-desactive" : "Modal-active"}>
        <div className="contend-modalC">
          <div className="ModalHeaderC">
            <h2>Agregar Proyecto</h2>
            <i className="fas fa-times" onClick={() => abrirCerrarModalInsertar()} id="icon-close"></i>
          </div>
          <div className="ModalBodyC">
            <div className="FormGroupC">
              <form onSubmit={peticionPost} id="Form-insertarC" >

                <label htmlFor="imagen_proyecto">Logo Empresa</label>
                <input id="imagen_proyecto" onChange={handleChanges} name="imagen_proyecto" accept="image/*" required type="file" />


                <div className="ModalFooter">
                  <input id="yes" type="submit" value="Guardar" />
                  <button onClick={() => abrirCerrarModalInsertar()} id="cancel">Cancelar</button>
                </div>
              </form>



            </div>
          </div>
        </div>
      </div>



    </Fragment>
  );
}

export default ModalAltaClientes;
