import React, {Fragment} from 'react';
import './App.css';
import NavBar from './componentes/navbar/NavBar';
import Slider from './componentes/slider/Slider';
import QuienesSomos from './componentes/quienesSomos/QuienesSomos';
import Footer from './componentes/footer/Footer';

const App = () => {
  return (
    <Fragment>
    <NavBar />
     <div className="contenedor-app">
        <Slider />

          <QuienesSomos />
        <Footer />
     </div>

    </Fragment>
  )
}

export default App;
