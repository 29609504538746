import React, { Fragment } from 'react';
import './Footer.css';
import {Link} from 'react-router-dom';
import BotonContacto from '../botonContacto/BotonContacto';


import alemania from '../../imagenes/alemania.png';
import granbretana from '../../imagenes/granbretana.png';
import francia from '../../imagenes/francia.png';


const Footer = () => {

    var d = new Date();
    var dia = new Array(7);
    dia[0] = "Domingo";
    dia[1] = "Lunes";
    dia[2] = "Martes";
    dia[3] = "Miercoles";
    dia[4] = "Jueves";
    dia[5] = "Viernes";
    dia[6] = "Sabado";
    dia[6] = "Domingo";

    const diaSeleccionado = { fontWeight: "bold", color:"black" };
    const diaNoSeleccionado = {};



    return (
        <Fragment>
            <BotonContacto />
            <div className="contenedor-footer">

                <div className="footer-contenido-pagina">

                    <div  data-aos="fade-right" className="footer-horarios">

                        <h2>Horario de atención</h2>
                        <div className="contenedor-horario">
                            <div style={dia[d.getDay()] === "Lunes" ? diaSeleccionado : diaNoSeleccionado} className="contenedor-dia-hora">
                                <div>Lunes</div>
                                <div>8:00 AM - 6:00 PM</div>
                            </div>
                            <div style={dia[d.getDay()] === "Martes" ? diaSeleccionado : diaNoSeleccionado} className="contenedor-dia-hora">
                                <div>Martes</div>
                                <div>8:00 AM - 6:00 PM</div>
                            </div>
                            <div style={dia[d.getDay()] === "Miercoles" ? diaSeleccionado : diaNoSeleccionado} className="contenedor-dia-hora">
                                <div>Miércoles</div>
                                <div>8:00 AM - 6:00 PM</div>
                            </div>
                            <div style={dia[d.getDay()] === "Jueves" ? diaSeleccionado : diaNoSeleccionado} className="contenedor-dia-hora">
                                <div>Jueves</div>
                                <div>8:00 AM - 6:00 PM</div>

                            </div>
                            <div style={dia[d.getDay()] === "Viernes" ? diaSeleccionado : diaNoSeleccionado} className="contenedor-dia-hora">
                                <div>Viernes</div>
                                <div>8:00 AM - 6:00 PM</div>
                            </div>
                            <div style={dia[d.getDay()] === "Sabado" ? diaSeleccionado : diaNoSeleccionado} className="contenedor-dia-hora">
                                <div>Sábado</div>
                                <div>8:00 AM - 2:00 PM</div>
                            </div>

                            <div style={dia[d.getDay()] === "Domingo" ? diaSeleccionado : diaNoSeleccionado} className="contenedor-dia-hora">
                                <div>Domingo</div>
                                <div>Cerrado</div>
                            </div>
                        </div>

                    </div>


                    <div data-aos="fade-up" className="footer-redes-sociales">
                        <h2>Siganos en</h2>

                        <div>
                            <a href="https://api.whatsapp.com/message/L2OKG5XKUZBNG1" title="WhatsApp" target="blank" > <i className="fab fa-whatsapp"></i>    </a>
                            <a href="https://www.facebook.com/GrupoCitraSaDeCv" title="Facebook" target="blank" > <i className="fab fa-facebook-f"></i>     </a>
                            <a href="https://www.facebook.com/messages/t/352408271464253" title="Messenger" target="blank" > <i className="fab fa-facebook-messenger"></i>    </a>
                            <a href="https://www.instagram.com/grupocitra/" title="Instagram" target="blank" > <i className="fab fa-instagram"></i>    </a>
                            <a href="https://www.linkedin.com/company/grupo-citra-s-a-de-c-v" title="Linkedin" target="blank" > <i className="fab fa-linkedin"></i>    </a>
                        </div>


                        <div> 
                                <h3>Cotiza con nosotros y déjate sorprender con todas las posibilidades que tenemos para tí.</h3>
                        </div>

                    </div>


                    <div className="footer-idiomas">
                 

                        <div data-aos="fade-left" className="idiomas">
                            <h3  title="Inglés" target="blank" > <img src={granbretana} alt="Inglés" />    </h3>
                            <h3 title="Alemán" target="blank" > <img src={alemania} alt="Alemám" />     </h3>
                            <h3 title="Francés" target="blank" > <img src={francia} alt="Francés" />    </h3>
                        </div>

                    </div>

                </div>

                <div className="footer-pie-pagina"  >
                  <Link to="/"> <h1>&copy; 2021 Copyright: Grupo Citra S.A DE C.V.</h1> </Link> 
                  <Link to="/contacto"> <h1></h1> </Link> 
                </div>

                <div className="footer-pie-pagina-direc" > 
                <a href="https://www.google.com/maps/place/CITRA+S.A.+DE+C.V./@20.9881939,-89.5756538,18z/data=!3m1!4b1!4m5!3m4!1s0x8f5670cf18011f9f:0xc501d23123471d2f!8m2!3d20.9881939!4d-89.5745595" target="_blank" >
                    <h2>Calle 33 A x 24 No. 373, Polígono 108, 97143 Mérida, Yuc.</h2>
                </a>
                </div>

            </div>
        </Fragment>
    )
}

export default Footer;