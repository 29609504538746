import React, {Fragment} from 'react';
import './Footer.css';
import {Link} from 'react-router-dom';

const Footer = () =>{
    return(
        <Fragment>
            <div className="content-footer">
                <Link to="/"> &copy; 2021 Copyright: Grupo Citra S.A DE C.V. </Link>
            </div>
        </Fragment>
    );  
}

export default Footer;